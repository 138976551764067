export default function reducer(state={
		category: [],
		categories: [],
		parents: [],
        children: [],
        allParentsCategories: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_CATEGORIES": {
			return {...state, fetching: true}
		}
		case "FETCH_CATEGORIES_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_CATEGORIES_FULFILLED": {
			return {...state, fetching: false, fetched: true, categories: action.payload.categories}
		}
		case "CREATE_CATEGORY": {
			return {...state, fetching: true}
		}
		case "CREATE_CATEGORY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_CATEGORY_FULFILLED": {
			return {...state, fetching: false, fetched: true, category: action.payload.category, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_CATEGORY": {
			return {...state, fetching: true}
		}
		case "FETCH_CATEGORY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_CATEGORY_FULFILLED": {
			return {...state, fetching: false, fetched: true, category: action.payload.category, parents: action.payload.parents, children: action.payload.children, allParentsCategories: action.payload.all_parents_categories}
		}
		case "UPDATE_CATEGORY": {
			return {...state, fetching: true}
		}
		case "UPDATE_CATEGORY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_CATEGORY_FULFILLED": {
			return {...state, fetching: false, fetched: true, category: action.payload.category}
		}
		case "DELETE_CATEGORY": {
			return {...state, fetching: true}
		}
		case "DELETE_CATEGORY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_CATEGORY_FULFILLED": {
			return {...state, fetching: false, fetched: true, categories: action.payload.categories}
		}
		default: {
        	break;
		}
	}

	return state
}