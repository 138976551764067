export default function reducer(state={
		contract: [],
		contracts: [],
		fetching: false,
		fetched: false,
		error: false,
		users: [],
		products: [],
		messages: []
	}, action) {

	switch (action.type) {
		case "FETCH_ALL_CONTRACTS": {
			return {...state, fetching: true}
		}
		case "FETCH_ALL_CONTRACTS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_ALL_CONTRACTS_FULFILLED": {
			return {...state, fetching: false, fetched: true, contracts: action.payload.contracts}
		}
		case "FETCH_CONTRACTS": {
			return {...state, fetching: true}
		}
		case "FETCH_CONTRACTS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_CONTRACTS_FULFILLED": {
			return {...state, fetching: false, fetched: true, contracts: action.payload.contracts, users: action.payload.users}
		}
		case "CREATE_CONTRACT": {
			return {...state, fetching: true}
		}
		case "CREATE_CONTRACT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_CONTRACT_FULFILLED": {
			return {...state, fetching: false, fetched: true, contract: action.payload.contract, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_CONTRACT": {
			return {...state, fetching: true}
		}
		case "FETCH_CONTRACT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_CONTRACT_FULFILLED": {
			return {...state, fetching: false, fetched: true, contract: action.payload.contract, products: action.payload.products, messages: action.payload.messages}
		}
		case "CLEAR_CONTRACT": {
			return {...state, fetching: false, fetched: true, contract: [], products: [], messages: []}
		}
		case "UPDATE_CONTRACT": {
			return {...state, fetching: true}
		}
		case "UPDATE_CONTRACT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_CONTRACT_FULFILLED": {
			return {...state, fetching: false, fetched: true, contract: action.payload.contract}
		}
		case "DELETE_CONTRACT": {
			return {...state, fetching: true}
		}
		case "DELETE_CONTRACT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_CONTRACT_FULFILLED": {
			return {...state, fetching: false, fetched: true, contracts: action.payload.contracts}
		}
		default: {
        	break;
		}
	}

	return state
}