export default function reducer(state={
		transfer: [],
		transfers: [],
		fetching: false,
		fetched: false,
		error: false,
		users: [],
		products: [],
		messages: []
	}, action) {

	switch (action.type) {
		case "FETCH_ALL_TRANSFERS": {
			return {...state, fetching: true}
		}
		case "FETCH_ALL_TRANSFERS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_ALL_TRANSFERS_FULFILLED": {
			return {...state, fetching: false, fetched: true, transfers: action.payload.transfers}
		}
		case "FETCH_TRANSFERS": {
			return {...state, fetching: true}
		}
		case "FETCH_TRANSFERS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_TRANSFERS_FULFILLED": {
			return {...state, fetching: false, fetched: true, transfers: action.payload.transfers, users: action.payload.users}
		}
		case "CREATE_TRANSFER": {
			return {...state, fetching: true}
		}
		case "CREATE_TRANSFER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_TRANSFER_FULFILLED": {
			return {...state, fetching: false, fetched: true, transfer: action.payload.transfer, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_TRANSFER": {
			return {...state, fetching: true}
		}
		case "FETCH_TRANSFER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_TRANSFER_FULFILLED": {
			return {...state, fetching: false, fetched: true, transfer: action.payload.transfer, messages: action.payload.messages}
		}
		case "UPDATE_TRANSFER": {
			return {...state, fetching: true}
		}
		case "UPDATE_TRANSFER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_TRANSFER_FULFILLED": {
			return {...state, fetching: false, fetched: true, transfer: action.payload.transfer}
		}
		case "DELETE_TRANSFER": {
			return {...state, fetching: true}
		}
		case "DELETE_TRANSFER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_TRANSFER_FULFILLED": {
			return {...state, fetching: false, fetched: true}
		}
		default: {
        	break;
		}
	}

	return state
}