export default function reducer(state={
		resource: [],
		resources: [],
		products: [],
		last_year_products: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_RESOURCES": {
			return {...state, fetching: true}
		}
		case "FETCH_RESOURCES_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_RESOURCES_FULFILLED": {
			return {...state, fetching: false, fetched: true, resources: action.payload.resources}
		}
		case "CREATE_RESOURCE": {
			return {...state, fetching: true}
		}
		case "CREATE_RESOURCE_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_RESOURCE_FULFILLED": {
			return {...state, fetching: false, fetched: true, resource: action.payload.resource, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_RESOURCE": {
			return {...state, fetching: true}
		}
		case "FETCH_RESOURCE_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_RESOURCE_FULFILLED": {
			return {...state, fetching: false, fetched: true, resource: action.payload.resource, products: action.payload.products, last_year_products: action.payload.last_year_products}
		}
		case "UPDATE_RESOURCE": {
			return {...state, fetching: true}
		}
		case "UPDATE_RESOURCE_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_RESOURCE_FULFILLED": {
			return {...state, fetching: false, fetched: true, resource: action.payload.resource}
		}
		case "DELETE_RESOURCE": {
			return {...state, fetching: true}
		}
		case "DELETE_RESOURCE_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_RESOURCE_FULFILLED": {
			return {...state, fetching: false, fetched: true, resources: action.payload.resources}
		}
		case "CLEAR_RESOURCE_PRODUCTS": {
			return {...state, products: [], last_year_products: [] }
		}
		default: {
        	break;
		}
	}

	return state
}