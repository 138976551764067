export default function reducer(state={
		order: [],
		invoice: [],
		contract: [],
		products: [],
		danger_data: [],
		products_by_oid: [],
		back_products: [],
		fetching: false,
		fetched: false,
		error: null
	}, action) {

	switch (action.type) {
		case "FETCH_BACK_PRODUCTS": {
			return {...state, fetching: true}
		}
		case "FETCH_BACK_PRODUCTS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_BACK_OPTION_PRODUCTS_BYOID_FULFILLED": {
			return {...state, fetching: false, fetched: true, back_products: action.payload.products}
		}
		case "FETCH_BACK_PRODUCTS_BYOID_FULFILLED": {
			return {...state, fetching: false, fetched: true, products_by_oid: action.payload.products}
		}
		case "FETCH_BACK_PRODUCTS_DANGER_NAME_FULFILLED": {
			return {...state, fetching: false, fetched: true, danger_data: action.payload.products}
		}
		case "FETCH_BACK_PRODUCTS_FULFILLED": {
			return {...state, fetching: false, fetched: true, products: action.payload.products}
		}
		case "CREATE_BACK_PRODUCT": {
			return {...state, fetching: true}
		}
		case "CREATE_BACK_PRODUCT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_BACK_PRODUCT_FULFILLED": {
			return {...state, fetching: false, fetched: true, products: action.payload.products, order: action.payload.order, messages: action.payload.messages}
		}
		case "UPDATE_BACK_PRODUCT": {
			return {...state, fetching: true}
		}
		case "UPDATE_BACK_PRODUCT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_BACK_PRODUCT_FULFILLED": {
			return {...state, fetching: false, fetched: true, products: action.payload.products, order: action.payload.order, invoice: action.payload.invoice, contract: action.payload.contract, messages: action.payload.messages}
		}
		case "DELETE_BACK_PRODUCT": {
			return {...state, fetching: true}
		}
		case "DELETE_BACK_PRODUCT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_BACK_PRODUCT_FULFILLED": {
			return {...state, fetching: false, fetched: true,products: action.payload.products, order: action.payload.order, messages: action.payload.messages}
		}
		case "REMOVE_BACK_PRODUCT": {
			return {...state, fetching: true}
		}
		case "REMOVE_BACK_PRODUCT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "REMOVE_BACK_PRODUCT_FULFILLED": {
			return {...state, fetching: false, fetched: true, products: action.payload.products, invoice: action.payload.invoice, contract: action.payload.contract, messages: action.payload.messages}
		}
		default: {
        	break;
		}
	}

	return state
}