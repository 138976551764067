export default function reducer(state={
		groups: [],
		invoice: [],
		invoices: [],
		products: [],
		messages: [],
		relations: [],
		files: [],
		invoice_dhl: null,
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_INVOICE_FILES_FULFILLED": {
			return {...state, fetching: false, fetched: true, files: action.payload.files, error: false}
		}
		case "FETCH_ALL_INVOICES": {
			return {...state, fetching: true}
		}
		case "FETCH_ALL_INVOICES_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_ALL_INVOICES_FULFILLED": {
			return {...state, fetching: false, fetched: true, invoices: action.payload.invoices}
		}
		case "FETCH_INVOICES": {
			return {...state, fetching: true}
		}
		case "FETCH_INVOICES_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_INVOICES_FULFILLED": {
			return {...state, fetching: false, fetched: true, invoices: action.payload.invoices, groups: action.payload.groups}
		}
		case "CREATE_INVOICE": {
			return {...state, fetching: true}
		}
		case "CREATE_INVOICE_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_INVOICE_FULFILLED": {
			return {...state, fetching: false, fetched: true, invoice: action.payload.invoice, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_INVOICE": {
			return {...state, fetching: true}
		}
		case "FETCH_INVOICE_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_INVOICE_FULFILLED": {
			return {...state, fetching: false, fetched: true, invoice: action.payload.invoice, products: action.payload.products, messages: action.payload.messages, relations: action.payload.relations, files: action.payload.files, groups: action.payload.groups}
		}
		case "FETCH_INVOICE_DHL_FULFILLED": {
			return {...state, fetching: false, fetched: true, invoice_dhl: action.payload.InvoiceDhl }
		}
		case "CLEAR_INVOICE": {
			return {...state, fetching: false, fetched: true, invoice: [], products: [], messages: [], relations: [], files: [], groups: []}
		}
		case "UPDATE_INVOICE": {
			return {...state, fetching: true}
		}
		case "UPDATE_INVOICE_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_INVOICE_FULFILLED": {
			return {...state, fetching: false, fetched: true, invoice: action.payload.invoice, messages: action.payload.messages}
		}
		case "DELETE_INVOICE": {
			return {...state, fetching: true}
		}
		case "DELETE_INVOICE_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_INVOICE_FULFILLED": {
			return {...state, fetching: false, fetched: true, invoices: action.payload.invoices}
		}
		default: {
        	break;
		}
	}

	return state
}