export default function reducer(state={
		payments: [],
		order: [],
		parents: [],
		fetching: false,
		fetched: false,
		error: null
	}, action) {

	switch (action.type) {
		// case "FETCH_PAYMENTS": {
		// 	return {...state, fetching: true}
		// }
		case "FETCH_ZTNS_FULFILLED": {
			return {...state, fetching: false, fetched: true, ztns: action.payload.ztns}
		}
		case "FETCH_ZTNS_PARENT_FULFILLED": {
			return {...state, fetching: false, fetched: true, parents: action.payload.parents}
		}
		case "FETCH_PAYMENTS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		// case "CREATE_PAYMENT": {
		// 	return {...state, fetching: true}
		// }
		// case "CREATE_PAYMENT_REJECTED": {
		// 	return {...state, fetching: false, error: action.payload}
		// }
		// case "CREATE_PAYMENT_FULFILLED": {
		// 	return {...state, fetching: false, fetched: true, payments: action.payload.payments, order: action.payload.order}
		// }
		// case "UPDATE_PAYMENT": {
		// 	return {...state, fetching: true}
		// }
		// case "UPDATE_PAYMENT_REJECTED": {
		// 	return {...state, fetching: false, error: action.payload}
		// }
		// case "UPDATE_PAYMENT_FULFILLED": {
		// 	return {...state, fetching: false, fetched: true, payments: action.payload.payments, order: action.payload.order}
		// }
		// case "DELETE_PAYMENT": {
		// 	return {...state, fetching: true}
		// }
		// case "DELETE_PAYMENT_REJECTED": {
		// 	return {...state, fetching: false, error: action.payload}
		// }
		// case "DELETE_PAYMENT_FULFILLED": {
		// 	return {...state, fetching: false, fetched: true, payments: action.payload.payments, order: action.payload.order}
		// }
		default: {
        	break;
		}
	}

	return state
}