export default function reducer(state={
		company: [],
		clients: [],
		products: [],
		companys: [],
		buy: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_CLIENTS": {
			return {...state, fetching: true}
		}
		case "FETCH_BUYS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_BUYS_FULFILLED": {
			return {...state, fetching: false, fetched: true, companys: action.payload.companys}
		}
		case "CREATE_CLIENT": {
			return {...state, fetching: true}
		}
		case "CREATE_BUYS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_BUYS_FULFILLED": {
			return {...state, fetching: false, fetched: true, company: action.payload.company, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_CLIENT": {
			return {...state, fetching: true}
		}
		case "FETCH_BUY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_BUY_FULFILLED": {
			return {...state, fetching: false, fetched: true, buy: action.payload.buy}
		}
		case "UPDATE_CLIENT": {
			return {...state, fetching: true}
		}
		case "UPDATE_BUY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_BUY_FULFILLED": {
			return {...state, fetching: false, fetched: true, buy: action.payload.company}
		}
		case "DELETE_BUY": {
			return {...state, fetching: true}
		}
		case "DELETE_BUY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_BUY_FULFILLED": {
			return {...state, fetching: false, fetched: true }
		}
		default: {
        	break;
		}
	}

	return state
}