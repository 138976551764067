import { Cookies } from 'react-cookie';

export default function reducer(state={
		user: [],
		roles: [],
		token: '',
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_USER": {
			const cookies = new Cookies();
			return {...state, fetching: false, fetched: true, user: cookies.get('user'), token: cookies.get('token'), error: false}
		}
		case "FETCH_USER_FULLDATA": {
			return {...state, fetching: true}
		}
		case "FETCH_USER_FULLDATA_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_ROLES_FULLDATA_FULFILLED": {
			return {...state, fetching: false, fetched: true, roles: action.payload.data, error: false}
		}
		case "FETCH_ROLES_FULLDATA_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_USER_FULLDATA_FULFILLED": {
			const cookies = new Cookies();
			return {...state, fetching: false, fetched: true, user: action.payload.user, token: cookies.get('token')}
		}
		case "UPDATE_USER_MAIL_CONFIG": {
			return {...state, fetching: true}
		}
		case "UPDATE_USER_MAIL_CONFIG_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_USER_MAIL_CONFIG_FULFILLED": {
			const cookies = new Cookies();
			return {...state, fetching: false, fetched: true, user: action.payload.user, token: cookies.get('token')}
		}
		default: {
        	break;
		}
	}

	return state
}