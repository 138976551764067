export default function reducer(state={
		users: [],
		roles: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_USERS": {
			return {...state, fetching: true}
		}
		case "FETCH_USERS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_USERS_FULFILLED": {
			return {...state, fetching: false, fetched: true, users: action.payload.users}
		}
		case "FETCH_ROLES_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_ROLES_FULFILLED": {
			return {...state, fetching: false, fetched: true, roles: action.payload.roles}
		}
		default: {
        	break;
		}
	}

	return state
}