export default function reducer(state={
		member: [],
		members: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_MEMBERS": {
			return {...state, fetching: true}
		}
		case "FETCH_MEMBERS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_MEMBERS_FULFILLED": {
			return {...state, fetching: false, fetched: true, members: action.payload.members}
		}
		case "CREATE_MEMBER": {
			return {...state, fetching: true}
		}
		case "CREATE_MEMBER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_MEMBER_FULFILLED": {
			return {...state, fetching: false, fetched: true, members: action.payload.members, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_MEMBER": {
			return {...state, fetching: true}
		}
		case "FETCH_MEMBER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_MEMBER_FULFILLED": {
			return {...state, fetching: false, fetched: true, member: action.payload.member}
		}
		case "UPDATE_MEMBER": {
			return {...state, fetching: true}
		}
		case "UPDATE_MEMBER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_MEMBER_FULFILLED": {
			return {...state, fetching: false, fetched: true, members: action.payload.members}
		}
		case "DELETE_MEMBER": {
			return {...state, fetching: true}
		}
		case "DELETE_MEMBER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_MEMBER_FULFILLED": {
			return {...state, fetching: false, fetched: true, members: action.payload.members}
		}
		default: {
        	break;
		}
	}

	return state
}