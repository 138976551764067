export default function reducer(state = {
  response: [],
  order: [],
  fetching: false,
  fetched: false,
  error: null
}, action) {

  switch (action.type) {
		case "UPDATE_PRICE_OK": {
			return {...state, fetching: false, fetched: true, response: action.payload}
		}
		case "UPDATE_PRICE_ERR": {
			return {...state, fetching: false, error: action.payload.response.data}
		}
    default: {
      break;
    }
  }

  return state
}