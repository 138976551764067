export default function reducer(state={
		tor: {},
		tors: [],
		torsp: [],
		boxs: [],
		files: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_TOR_FILES_FULFILLED": {
			return {...state, fetching: false, fetched: true, files: action.payload.files, error: false}
		}
		case "FETCH_BOX_OPTION_FULFILLED": {
			return {...state, fetching: false, fetched: true, boxs: action.payload.boxs, error: false}
		}
		case "FETCH_TORS_FIND_FULFILLED": {
			return {...state, fetching: false, fetched: true, tor: action.payload, error: false}
		}
		case "FETCH_TORS_OPTION_FULFILLED": {
			return {...state, fetching: false, fetched: true, tors: action.payload.tors, error: false}
		}
		case "FETCH_TORS_OPTION_REJECTED": {
			return {...state, fetching: false, fetched: true, tors: [], error: false}
		}
		case "FETCH_TORSP_OPTION_FULFILLED": {
			return {...state, fetching: false, fetched: true, torsp: action.payload.tors, error: false}
		}
		default: {
        	break;
		}
	}

	return state
}