export default function reducer(state={
		logistic: {},
		logistics: [],
		office: {},
		offices: [],
		messages: [],
		calendar: [],
		vacation: {},
		createVacation: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_REPORT_LOGISTICS_FULFILLED": {
			return {...state, fetching: false, fetched: true, logistics: action.payload.logistics}
		}
		case "FETCH_VACATION_CALENDAR_FULFILLED": {
			return {...state, fetching: false, fetched: true, calendar: action.payload, }
		}
		case "CREATE_REPORT_LOGISTICS_FULFILLED": {
			return {...state, fetching: false, fetched: true, logistic: action.payload.logistic, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_REPORT_LOGISTIC_FULFILLED": {
			return {...state, fetching: false, fetched: true, logistic: action.payload.logistic, messages: action.payload.messages, }
		}
		case "UPDATE_REPORT_LOGISTIC_FULFILLED": {
			return {...state, fetching: false, fetched: true, logistic: action.payload.logistic, messages: action.payload.messages}
		}
		case "DELETE_REPORT_LOGISTIC_FULFILLED": {
			return {...state, fetching: false, fetched: true, logistics: action.payload.logistics}
		}

		case "FETCH_REPORT_OFFICES_FULFILLED": {
			return {...state, fetching: false, fetched: true, offices: action.payload.offices}
		}
		case "CREATE_REPORT_OFFICES_FULFILLED": {
			return {...state, fetching: false, fetched: true, office: action.payload.office, error: (action.payload.error) ? action.payload.error : false}
		}
		case "CREATE_REPORT_VACATION_FULFILLED": {
			return {...state, fetching: false, fetched: true, createVacation: action.payload.vacation, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_REPORT_VACATION_FULFILLED": {
			return {...state, fetching: false, fetched: true, vacation: action.payload.vacation, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_REPORT_OFFICE_FULFILLED": {
			return {...state, fetching: false, fetched: true, office: action.payload.office, messages: action.payload.messages, }
		}
		case "UPDATE_REPORT_OFFICE_FULFILLED": {
			return {...state, fetching: false, fetched: true, office: action.payload.office, messages: action.payload.messages}
		}
		case "DELETE_REPORT_OFFICE_FULFILLED": {
			return {...state, fetching: false, fetched: true, offices: action.payload.offices}
		}

		default: {
        	break;
		}
	}

	return state
}