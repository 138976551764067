export default function reducer(state={
		messages: [],
		barMessages: [],
		fetching: false,
		fetched: false,
		error: null
	}, action) {

	switch (action.type) {
		case "CREATE_MESSAGE": {
			return {...state, fetching: true}
		}
		case "CREATE_MESSAGE_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_MESSAGE_FULFILLED": {
			return {...state, fetching: false, fetched: true, messages: action.payload.messages}
		}
		case "FETCH_MESSAGE_FULFILLED": {
			return {...state, fetching: false, fetched: true, messages: action.payload}
		}
		case "FETCH_BARMESSAGE_FULFILLED": {
			return {...state, fetching: false, fetched: true, barMessages: action.payload}
		}
		case "UPDATE_MESSAGE": {
			return {...state, fetching: true}
		}
		case "UPDATE_MESSAGE_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_MESSAGE_FULFILLED": {
			return {...state, fetching: false, fetched: true, messages: action.payload.messages}
		}
		case "UPDATE_RELATION_DESC_MESSAGE_FULFILLED": {
			return {...state, fetching: false, fetched: true, messages: action.payload.messages}
		}
		case "DELETE_MESSAGE": {
			return {...state, fetching: true}
		}
		case "DELETE_MESSAGE_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_MESSAGE_FULFILLED": {
			return {...state, fetching: false, fetched: true, messages: action.payload.messages}
		}
		default: {
        	break;
		}
	}

	return state
}