export default function reducer(state={
		brand: [],
		brands: [],
		products: [],
		resources: [],
		categories: [],
		last_year_products: [],
		brandsMenu: false,
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_BRANDS": {
			return {...state, fetching: true}
		}
		case "FETCH_BRANDS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_BRANDS_FULFILLED": {
			return {...state, fetching: false, fetched: true, brands: action.payload.brands}
		}
		case "FETCH_BRANDS_MENU_FULFILLED": {
			return {...state, fetching: false, fetched: true, brandsMenu: action.payload.permission}
		}
		case "CREATE_BRAND": {
			return {...state, fetching: true}
		}
		case "CREATE_BRAND_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_BRAND_FULFILLED": {
			return {...state, fetching: false, fetched: true, brand: action.payload.brand, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_BRAND": {
			return {...state, fetching: true}
		}
		case "FETCH_BRAND_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_BRAND_FULFILLED": {
			return {...state, fetching: false, fetched: true, brand: action.payload.brand, resources: action.payload.resources, products: action.payload.products, last_year_products: action.payload.last_year_products, categories: action.payload.categories}
		}
		case "CLEAR_BRAND_LASTYEAR_PRODUCTS": {
			return {...state, fetching: false, fetched: true, products: [], last_year_products: [] }
		}
		case "CLEAR_BRAND": {
			return {...state, fetching: false, fetched: true, brand: [], resources: [], products: [], categories: []}
		}
		case "UPDATE_BRAND": {
			return {...state, fetching: true}
		}
		case "UPDATE_BRAND_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_BRAND_FULFILLED": {
			return {...state, fetching: false, fetched: true, brand: action.payload.brand}
		}
		case "DELETE_BRAND": {
			return {...state, fetching: true}
		}
		case "DELETE_BRAND_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_BRAND_FULFILLED": {
			return {...state, fetching: false, fetched: true, brands: action.payload.brands}
		}
		default: {
        	break;
		}
	}

	return state
}