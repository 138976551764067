export default function reducer(state={
		order: [],
		invoice: [],
		contract: [],
		products: [],
		danger_data: [],
		products_by_oid: [],
		same_data: [],
		stocks: [],
		inventory: [],
		last_year_products: [],
		createStock: {},
		stock: {},
		tariffnumber_options: [],
		fetching: false,
		fetched: false,
		error: null
	}, action) {

	switch (action.type) {
		case "CLEAR_PRODUCTS": {
			return {...state, fetching: false, fetched: true, products: [], order: [], messages: []}
		}
		case "FETCH_PRODUCTS": {
			return {...state, fetching: true}
		}
		case "FETCH_PRODUCTS_REJECTED": {
			return {...state, fetching: false, error: action.payload, same_data: [] }
		}
		case "FETCH_PRODUCTS_BYIID_FULFILLED": {
			return {...state, fetching: false, products: action.payload.products, invoice: action.payload.invoice }
		}
		case "FETCH_PRODUCTS_BYOID_FULFILLED": {
			return {...state, fetching: false, fetched: true, products_by_oid: action.payload.products}
		}
		case "FETCH_STOCK_OPTIONS_FULFILLED": {
			return {...state, fetching: false, fetched: true, stocks: action.payload}
		}
		case "FETCH_PRODUCTS_DANGER_NAME_FULFILLED": {
			return {...state, fetching: false, fetched: true, danger_data: action.payload.products, same_data: []}
		}
		case "FETCH_CLEAR_PRODUCTS_SAME_FULFILLED": {
			return {...state, fetching: false, fetched: true, same_data: []}
		}
		case "FETCH_PRODUCTS_SAME_FULFILLED": {
			return {...state, fetching: false, fetched: true, same_data: action.payload.products}
		}
		case "FETCH_PRODUCTS_FULFILLED": {
			return {...state, fetching: false, fetched: true, products: action.payload.products}
		}
		case "FETCH_LAST_PRODUCTS_FULFILLED": {
			return {...state, fetching: false, fetched: true, last_year_products: action.payload.products}
		}
		case "FETCH_INVENTORY_PRODUCTS_FULFILLED": {
			return {...state, fetching: false, fetched: true, inventory: action.payload.products}
		}
		case "CREATE_PRODUCT": {
			return {...state, fetching: true}
		}
		case "CREATE_PRODUCT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_PRODUCT_FULFILLED": {
			return {...state, fetching: false, fetched: true, products: action.payload.products, order: action.payload.order, messages: action.payload.messages}
		}
		case "UPDATE_PRODUCT": {
			return {...state, fetching: true}
		}
		case "UPDATE_PRODUCT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_PRODUCT_FULFILLED": {
			return {...state, fetching: false, fetched: true, products: action.payload.products, order: action.payload.order, invoice: action.payload.invoice, contract: action.payload.contract, messages: action.payload.messages}
		}
		case "DELETE_PRODUCT": {
			return {...state, fetching: true}
		}
		case "DELETE_PRODUCT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_PRODUCT_FULFILLED": {
			return {...state, fetching: false, fetched: true,products: action.payload.products, order: action.payload.order, messages: action.payload.messages}
		}
		case "REMOVE_PRODUCT": {
			return {...state, fetching: true}
		}
		case "REMOVE_PRODUCT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "REMOVE_PRODUCT_FULFILLED": {
			return {...state, fetching: false, fetched: true, products: action.payload.products, invoice: action.payload.invoice, contract: action.payload.contract, messages: action.payload.messages}
		}
		case "CREATE_STOCK_FULFILLED": {
			return {...state, fetching: false, fetched: true, createStock: action.payload }
		}
		case "FETCH_STOCK_DATA_FULFILLED": {
			return {...state, fetching: false, fetched: true, stock: action.payload }
		}
		case "UPDATE_STOCK_DATA_FULFILLED": {
			return {...state, fetching: false, fetched: true, stock: action.payload }
		}
		case "DELETE_STOCK_FULFILLED": {
			return {...state, fetching: false, fetched: true, error: false}
		}
		case "FETCH_TARIFFNUMBERS_OPTIONS_FULFILLED": {
			return {...state, fetching: false, fetched: true, error: false, tariffnumber_options: action.payload}
		}
		default: {
        	break;
		}
	}

	return state
}