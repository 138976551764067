export default function reducer(state={
		inquiry: [],
		inquiries: [],
		items: [],
		messages: [],
		resources: [],
		relations: [],
		users: [],
		quotationRate: 0,
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_INQUIRIES": {
			return {...state, fetching: true}
		}
		case "FETCH_INQUIRIES_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_INQUIRIES_FULFILLED": {

            var totalInquiries = action.payload.inquiries.length;

            var inquiryWithQuotation = 0;

            var inquiryGetOrder = 0;

            for (var i = action.payload.inquiries.length - 1; i >= 0; i--) {
                if (action.payload.inquiries[i].status === 3) { 
                	inquiryWithQuotation++; 
                }
                else if (action.payload.inquiries[i].status === 5) { 
                	inquiryGetOrder++; 
                }
            }

            var quotationRate = (inquiryWithQuotation+inquiryGetOrder)/totalInquiries;

            var successRate = inquiryGetOrder/totalInquiries;

			return {...state, fetching: false, fetched: true, inquiries: action.payload.inquiries, quotationRate: quotationRate, users: action.payload.users, successRate: successRate}
		}
		case "CREATE_INQUIRY": {
			return {...state, fetching: true}
		}
		case "CREATE_INQUIRY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_INQUIRY_FULFILLED": {
			return {...state, fetching: false, fetched: true, inquiry: action.payload.inquiry, items: action.payload.items, messages: action.payload.messages, resources: action.payload.resources,  error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_INQUIRY": {
			return {...state, fetching: true}
		}
		case "FETCH_INQUIRY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_INQUIRY_FULFILLED": {
			return {...state, fetching: false, fetched: true, inquiry: action.payload.inquiry, items: action.payload.items, messages: action.payload.messages, resources: action.payload.resources, relations: action.payload.relations}
		}
		case "UPDATE_INQUIRY": {
			return {...state, fetching: true}
		}
		case "UPDATE_INQUIRY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_INQUIRY_FULFILLED": {
			return {...state, fetching: false, fetched: true, inquiry: action.payload.inquiry}
		}
		case "SEND_INQUIRY": {
			return {...state, fetching: true}
		}
		case "SEND_INQUIRY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "SEND_INQUIRY_FULFILLED": {
			return {...state, fetching: false, fetched: true, inquiry: action.payload.inquiry}
		}
		case "SEND_INQUIRY_QUOTATION": {
			return {...state, fetching: true}
		}
		case "SEND_INQUIRY_QUOTATION_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_INQUIRY_QUOTATION_FULFILLED": {
			return {...state, fetching: false, fetched: true, inquiry: action.payload.inquiry}
		}
		case "DELETE_INQUIRY": {
			return {...state, fetching: true}
		}
		case "DELETE_INQUIRY_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_INQUIRY_FULFILLED": {
			return {...state, fetching: false, fetched: true, inquiries: action.payload.inquiries}
		}
		default: {
        	break;
		}
	}

	return state
}