export default function reducer(state={
		contractPayments: [],
		order: [],
		fetching: false,
		fetched: false,
		error: null
	}, action) {

	switch (action.type) {
		case "FETCH_CONTRACT_PAYMENTS": {
			return {...state, fetching: true}
		}
		case "FETCH_CONTRACT_PAYMENTS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_CONTRACT_PAYMENTS_FULFILLED": {
			return {...state, fetching: false, fetched: true, contractPayments: action.payload.contract_payments}
		}
		case "CREATE_CONTRACT_PAYMENT": {
			return {...state, fetching: true}
		}
		case "CREATE_CONTRACT_PAYMENT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_CONTRACT_PAYMENT_FULFILLED": {
			return {...state, fetching: false, fetched: true, contractPayments: action.payload.contract_payments}
		}
		case "DELETE_CONTRACT_PAYMENT": {
			return {...state, fetching: true}
		}
		case "DELETE_CONTRACT_PAYMENT_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_CONTRACT_PAYMENT_FULFILLED": {
			return {...state, fetching: false, fetched: true, contractPayments: action.payload.contract_payments}
		}
		default: {
        	break;
		}
	}

	return state
}