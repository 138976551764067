export default function reducer(state={
		order: [],
		orders: [],
		products: [],
		relations: [],
		payments: [],
		messages: [],
		owner: [],
		contract: [],
		clients: [],
		line_chart: [],
		line_chart2: [],
		pie_chart: [],
		waybillOptions: [],
		hscodes: [],
		last_year_orders: [],
		files: [],
		allorders: [],
		twoorders: [],
		fetching: false,
		fetched: false,
		error: false,
	}, action) {

	switch (action.type) {
		case "FETCH_WAYBILL_FULFILLED": {
			return {...state, fetching: false, fetched: true, waybillOptions: action.payload, error: false }
		}
		case "FETCH_BACK_ORDERS_CHART_FULFILLED": {
			return {...state, fetching: false, fetched: true, line_chart: action.payload.line_chart, line_chart2: action.payload.line_chart2, pie_chart: action.payload.pie_chart, error: false}
		}
		case "FETCH_BACK_ORDERS": {
			return {...state, fetching: true, error: false}
		}
		case "FETCH_BACK_ORDERS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_HSCODE_FULFILLED": {
			return {...state, fetching: false, fetched: true, hscodes: action.payload.hscodes, error: false}
		}
		case "FETCH_BACK_ORDERS_FULFILLED": {
			return {...state, fetching: false, fetched: true, orders: action.payload.orders, clients: action.payload.clients, error: false }
		}
		case "FETCH_LAST_YEAR_BACK_ORDERS_FULFILLED": {
			return {...state, fetching: false, fetched: true, orders: action.payload.orders, last_year_orders: action.payload.last_year_orders, clients: action.payload.clients, error: false }
		}
		case "FETCH_ALL_BACK_ORDERS_FULFILLEDB": {
			return {...state, fetching: false, fetched: true, allorders: action.payload.orders, error: false}
		}
		case "FETCH_ALL_BACK_ORDERS_FULFILLEDTWO": {
			return {...state, fetching: false, fetched: true, twoorders: action.payload.orders, error: false}
		}
		case "FETCH_ALL_BACK_ORDERS_FULFILLED": {
			return {...state, fetching: false, fetched: true, orders: action.payload.orders, error: false}
		}
		case "CREATE_BACK_ORDER": {
			return {...state, fetching: true, error: false}
		}
		case "CREATE_BACK_ORDER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_BACK_ORDER_FULFILLED": {
			return {...state, fetching: false, fetched: true, order: action.payload.order, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_BACK_ORDER": {
			return {...state, fetching: true, error: false}
		}
		case "FETCH_BACK_ORDER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_BACK_ORDER_FULFILLED": {
			return {...state, fetching: false, fetched: true, order: action.payload.order, products: action.payload.products, relations: action.payload.relations, payments: action.payload.payments, messages: action.payload.messages, owner: action.payload.owner, contract: action.payload.contract, files: action.payload.files, error: false}
		}
		case "FETCH_BACK_ORDER_FILES_FULFILLED": {
			return {...state, fetching: false, fetched: true, files: action.payload.files, error: false}
		}
		case "UPDATE_BACK_ORDER": {
			return {...state, fetching: true, error: false}
		}
		case "UPDATE_BACK_ORDER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_BACK_ORDER_FULFILLED": {
			return {...state, fetching: false, fetched: true, order: action.payload.order, messages: action.payload.messages, error: false}
		}
		case "DELETE_BACK_ORDER": {
			return {...state, fetching: true, error: false}
		}
		case "DELETE_BACK_ORDER_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_BACK_ORDER_FULFILLED": {
			return {...state, fetching: false, fetched: true, orders: action.payload.orders, error: false}
		}
		default: {
        	break;
		}
	}

	return state
}