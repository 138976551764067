export default function reducer(state={
		items: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_ITEMS": {
			return {...state, fetching: true}
		}
		case "FETCH_ITEMS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_ITEMS_FULFILLED": {
			return {...state, fetching: false, fetched: true, items: action.payload.items}
		}
		case "CREATE_ITEM": {
			return {...state, fetching: true}
		}
		case "CREATE_ITEM_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_ITEM_FULFILLED": {
			return {...state, fetching: false, fetched: true, items: action.payload.items, error: (action.payload.error) ? action.payload.error : false}
		}
		case "UPDATE_ITEM": {
			return {...state, fetching: true}
		}
		case "UPDATE_ITEM_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_ITEM_FULFILLED": {
			return {...state, fetching: false, fetched: true, items: action.payload.items}
		}
		case "DELETE_ITEM": {
			return {...state, fetching: true}
		}
		case "DELETE_ITEM_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_ITEM_FULFILLED": {
			return {...state, fetching: false, fetched: true, items: action.payload.items}
		}
		default: {
        	break;
		}
	}

	return state
}