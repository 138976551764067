export default function reducer(state={
		colletion: [],
		colletions: [],
		fetching: false,
		fetched: false,
		error: false,
		parents: [],
	}, action) {

	switch (action.type) {
		case "FETCH_COLLECTIONS": {
			return {...state, fetching: true}
		}
		case "FETCH_COLLECTIONS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_COLLECTIONS_FULFILLED": {
			return {...state, fetching: false, fetched: true, colletions: action.payload.colletions, parents: action.payload.parents}
		}
		case "CREATE_COLLECTION": {
			return {...state, fetching: true}
		}
		case "CREATE_COLLECTION_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_COLLECTION_FULFILLED": {
			return {...state, fetching: false, fetched: true, colletion: action.payload.colletion, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_COLLECTION": {
			return {...state, fetching: true}
		}
		case "FETCH_COLLECTION_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_COLLECTION_FULFILLED": {
			return {...state, fetching: false, fetched: true, colletion: action.payload.colletion}
		}
		case "UPDATE_COLLECTION": {
			return {...state, fetching: true}
		}
		case "UPDATE_COLLECTION_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_COLLECTION_FULFILLED": {
			return {...state, fetching: false, fetched: true, colletion: action.payload.colletion}
		}
		case "DELETE_COLLECTION": {
			return {...state, fetching: true}
		}
		case "DELETE_COLLECTION_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_COLLECTION_FULFILLED": {
			return {...state, fetching: false, fetched: true, colletion: action.payload.colletion}
		}
		default: {
        	break;
		}
	}

	return state
}