import {combineReducers } from "redux";
import authentification from "./authentificationReducer";
import brands from "./brandsReducer";
import clients from "./clientsReducer";
import categories from "./categoriesReducer";
import collections from "./collectionsReducer";
import contracts from "./contractsReducer";
import inquiries from "./inquiriesReducer";
import invoices from "./invoicesReducer";
import items from "./itemsReducer";
import members from "./membersReducer";
import messages from "./messagesReducer";
import orders from "./ordersReducer";
import payments from "./paymentsReducer";
import inInvoice from "./inInvoiceReducer";
import ztns from "./ztnsReducer";
import products from "./productsReducer";
import relations from "./relationsReducer";
import resources from "./resourcesReducer";
import sellers from "./sellersReducer";
import users from "./usersReducer";
import admin from "./adminReducer";
import contractPayments from "./contractPaymentsReducer";
import waybills from "./waybillsReducer";
import freight from "./freightReducer";
import transfers from "./TransfersReducer";
import buys from "./buysReducer";
import report from "./reportReducer";
import priceStore from "./priceStore";
import backOrders from "./backOrdersReducer";
import backProducts from "./backProductsReducer";
import wiki from "./wikiReducer";
import tor from "./torReducer";

export default combineReducers ({
	authentification,
	brands,
	clients,
	categories,
	collections,
	contracts,
	inquiries,
	invoices,
	items,
	members,
	messages,
	orders,
	payments,
	inInvoice,
	ztns,
	products,
	relations,
	resources,
	sellers,
	users,
	admin,
	contractPayments,
	waybills,
	freight,
	transfers,
	buys,
	report,
	priceStore,
	backOrders,
	backProducts,
	wiki,
	tor,
})