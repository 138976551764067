export default function reducer(state={
		resource: [],
		resources: [],
		products: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_WAYBILL": {
			return {...state, fetching: true}
		}
		case "FETCH_WAYBILLS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_WAYBILLS_FULFILLED": {
			return {...state, fetching: false, fetched: true, resources: action.payload.resources}
		}
		case "CREATE_WAYBILL": {
			return {...state, fetching: true}
		}
		case "CREATE_WAYBILL_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "CREATE_WAYBILL_FULFILLED": {
			return {...state, fetching: false, fetched: true, resource: action.payload.resource, error: (action.payload.error) ? action.payload.error : false}
		}
		case "FETCH_WAYBILL": {
			return {...state, fetching: true}
		}
		case "FETCH_WAYBILL_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_WAYBILL_FULFILLED": {
			return {...state, fetching: false, fetched: true, resource: action.payload.resource, products: action.payload.products}
		}
		case "UPDATE_WAYBILL": {
			return {...state, fetching: true}
		}
		case "UPDATE_WAYBILL_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "UPDATE_WAYBILL_FULFILLED": {
			return {...state, fetching: false, fetched: true, resource: action.payload.resource}
		}
		case "DELETE_WAYBILL": {
			return {...state, fetching: true}
		}
		case "DELETE_WAYBILL_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "DELETE_WAYBILL_FULFILLED": {
			return {...state, fetching: false, fetched: true, resources: action.payload.resources}
		}
		default: {
        	break;
		}
	}

	return state
}