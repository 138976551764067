export default function reducer(state={
		users: [],
		calendar: [],
		calendar_day_minutes: [],
		calendar_month_minutes: [],
		calendar_year_minutes: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_USERS": {
			return {...state, fetching: true}
		}
		case "FETCH_USERS_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_USERS_FULFILLED": {
			return {...state, fetching: false, fetched: true, users: action.payload.users}
		}
		case "FETCH_USER_WORK_CALENDAR_FULFILLED": {
			return {...state, fetching: false, fetched: true, calendar: action.payload.calendar, calendar_day_minutes: action.payload.day_minutes, calendar_month_minutes: action.payload.month_minutes, calendar_year_minutes: action.payload.year_minutes}
		}
		default: {
        	break;
		}
	}

	return state
}