export default function reducer(state={
		InvoiceRelationsOptions: [],
		fetching: false,
		fetched: false,
		error: false
	}, action) {

	switch (action.type) {
		case "FETCH_InvoiceRelationOptions_REJECTED": {
			return {...state, fetching: false, error: action.payload}
		}
		case "FETCH_InvoiceRelationOptions_FULFILLED": {
			return {...state, fetching: false, fetched: true, InvoiceRelationsOptions: action.payload}
		}
		default: {
        	break;
		}
	}

	return state
}