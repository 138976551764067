export default function reducer(state={
		calendar: [],
		fetching: false,
		fetched: false,
		error: false,
	}, action) {

	switch (action.type) {
		case "FETCH_CALENDAR_FULFILLED": {
			return {...state, fetching: false, fetched: true, calendar: action.payload.calendar, }
		}
		case "FETCH_CALENDAR_REJECTED": {
			return {...state, fetching: true}
		}
		default: {
        	break;
		}
	}

	return state
}